
function Name_svg() {
    return (
        <svg className="name_svg" width="506" height="99" viewBox="0 0 506 99" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M486.609 74.8641C482.577 74.8641 478.961 74.1921 475.761 72.8481C472.561 71.4401 470.033 69.5201 468.177 67.0881C466.321 64.5921 465.297 61.7441 465.105 58.5441H474.129C474.385 61.1681 475.601 63.3121 477.777 64.9761C480.017 66.6401 482.929 67.4721 486.513 67.4721C489.841 67.4721 492.465 66.7361 494.385 65.2641C496.305 63.7921 497.265 61.9361 497.265 59.6961C497.265 57.3921 496.241 55.6961 494.193 54.6081C492.145 53.4561 488.977 52.3361 484.689 51.2481C480.785 50.2241 477.585 49.2001 475.089 48.1761C472.657 47.0881 470.545 45.5201 468.753 43.4721C467.025 41.3601 466.161 38.6081 466.161 35.2161C466.161 32.5281 466.961 30.0641 468.561 27.8241C470.161 25.5841 472.433 23.8241 475.377 22.5441C478.321 21.2001 481.681 20.5281 485.457 20.5281C491.281 20.5281 495.985 22.0001 499.569 24.9441C503.153 27.8881 505.073 31.9201 505.329 37.0401H496.593C496.401 34.2881 495.281 32.0801 493.233 30.4161C491.249 28.7521 488.561 27.9201 485.169 27.9201C482.033 27.9201 479.537 28.5921 477.681 29.9361C475.825 31.2801 474.897 33.0401 474.897 35.2161C474.897 36.9441 475.441 38.3841 476.529 39.5361C477.681 40.6241 479.089 41.5201 480.753 42.2241C482.481 42.8641 484.849 43.6001 487.857 44.4321C491.633 45.4561 494.705 46.4801 497.073 47.5041C499.441 48.4641 501.457 49.9361 503.121 51.9201C504.849 53.9041 505.745 56.4961 505.809 59.6961C505.809 62.5761 505.009 65.1681 503.409 67.4721C501.809 69.7761 499.537 71.6001 496.593 72.9441C493.713 74.2241 490.385 74.8641 486.609 74.8641Z" fill="white" />
            <path d="M399.846 47.504C399.846 42.128 400.934 37.424 403.11 33.392C405.286 29.296 408.262 26.128 412.038 23.888C415.878 21.648 420.166 20.528 424.902 20.528C428.998 20.528 432.806 21.488 436.326 23.408C439.846 25.264 442.534 27.728 444.39 30.8V2.95996H453.222V74H444.39V64.112C442.662 67.248 440.102 69.84 436.71 71.888C433.318 73.872 429.35 74.864 424.806 74.864C420.134 74.864 415.878 73.712 412.038 71.408C408.262 69.104 405.286 65.872 403.11 61.712C400.934 57.552 399.846 52.816 399.846 47.504ZM444.39 47.6C444.39 43.632 443.59 40.176 441.99 37.232C440.39 34.288 438.214 32.048 435.462 30.512C432.774 28.912 429.798 28.112 426.534 28.112C423.27 28.112 420.294 28.88 417.606 30.416C414.918 31.952 412.774 34.192 411.174 37.136C409.574 40.08 408.774 43.536 408.774 47.504C408.774 51.536 409.574 55.056 411.174 58.064C412.774 61.008 414.918 63.28 417.606 64.88C420.294 66.416 423.27 67.184 426.534 67.184C429.798 67.184 432.774 66.416 435.462 64.88C438.214 63.28 440.39 61.008 441.99 58.064C443.59 55.056 444.39 51.568 444.39 47.6Z" fill="white" />
            <path d="M388.222 2.95996V74H379.486V2.95996H388.222Z" fill="white" />
            <path d="M367.953 45.6801C367.953 47.3441 367.857 49.1041 367.665 50.9601H325.617C325.937 56.1441 327.697 60.2081 330.897 63.1521C334.161 66.0321 338.097 67.4721 342.705 67.4721C346.481 67.4721 349.617 66.6081 352.113 64.8801C354.673 63.0881 356.465 60.7201 357.489 57.7761H366.897C365.489 62.8321 362.673 66.9601 358.449 70.1601C354.225 73.2961 348.977 74.8641 342.705 74.8641C337.713 74.8641 333.233 73.7441 329.265 71.5041C325.361 69.2641 322.289 66.0961 320.049 62.0001C317.809 57.8401 316.689 53.0401 316.689 47.6001C316.689 42.1601 317.777 37.3921 319.953 33.2961C322.129 29.2001 325.169 26.0641 329.073 23.8881C333.041 21.6481 337.585 20.5281 342.705 20.5281C347.697 20.5281 352.113 21.6161 355.953 23.7921C359.793 25.9681 362.737 28.9761 364.785 32.8161C366.897 36.5921 367.953 40.8801 367.953 45.6801ZM358.929 43.8561C358.929 40.5281 358.193 37.6801 356.721 35.3121C355.249 32.8801 353.233 31.0561 350.673 29.8401C348.177 28.5601 345.393 27.9201 342.321 27.9201C337.905 27.9201 334.129 29.3281 330.993 32.1441C327.921 34.9601 326.161 38.8641 325.713 43.8561H358.929Z" fill="white" />
            <path d="M300.846 12.8481C299.182 12.8481 297.774 12.2721 296.622 11.1201C295.534 9.96809 294.99 8.56009 294.99 6.89609C294.99 5.23209 295.534 3.82409 296.622 2.67209C297.774 1.52009 299.182 0.944092 300.846 0.944092C302.51 0.944092 303.886 1.52009 304.974 2.67209C306.126 3.82409 306.702 5.23209 306.702 6.89609C306.702 8.56009 306.126 9.96809 304.974 11.1201C303.886 12.2721 302.51 12.8481 300.846 12.8481ZM305.166 85.4241C305.166 90.0961 303.982 93.5201 301.614 95.6961C299.246 97.8721 295.79 98.9601 291.246 98.9601H286.158V91.5681H289.806C292.238 91.5681 293.934 91.0881 294.894 90.1281C295.918 89.1681 296.43 87.5361 296.43 85.2321V21.3921H305.166V85.4241Z" fill="white" />
            <path d="M274.962 74.0001L247.41 43.4721V74.0001H238.674V7.08813H247.41V38.0961L275.058 7.08813H286.098L255.762 40.5921L286.386 74.0001H274.962Z" fill="white" />
            <path d="M201.547 45.6801C201.547 47.3441 201.451 49.1041 201.259 50.9601H159.211C159.531 56.1441 161.291 60.2081 164.491 63.1521C167.755 66.0321 171.691 67.4721 176.299 67.4721C180.075 67.4721 183.211 66.6081 185.707 64.8801C188.267 63.0881 190.059 60.7201 191.083 57.7761H200.491C199.083 62.8321 196.267 66.9601 192.043 70.1601C187.819 73.2961 182.571 74.8641 176.299 74.8641C171.307 74.8641 166.827 73.7441 162.859 71.5041C158.955 69.2641 155.883 66.0961 153.643 62.0001C151.403 57.8401 150.283 53.0401 150.283 47.6001C150.283 42.1601 151.371 37.3921 153.547 33.2961C155.723 29.2001 158.763 26.0641 162.667 23.8881C166.635 21.6481 171.179 20.5281 176.299 20.5281C181.291 20.5281 185.707 21.6161 189.547 23.7921C193.387 25.9681 196.331 28.9761 198.379 32.8161C200.491 36.5921 201.547 40.8801 201.547 45.6801ZM192.523 43.8561C192.523 40.5281 191.787 37.6801 190.315 35.3121C188.843 32.8801 186.827 31.0561 184.267 29.8401C181.771 28.5601 178.987 27.9201 175.915 27.9201C171.499 27.9201 167.723 29.3281 164.587 32.1441C161.515 34.9601 159.755 38.8641 159.307 43.8561H192.523Z" fill="white" />
            <path d="M126.472 29.9361C128.008 26.9281 130.184 24.5921 133 22.9281C135.88 21.2641 139.368 20.4321 143.464 20.4321V29.4561H141.16C131.368 29.4561 126.472 34.7681 126.472 45.3921V74.0001H117.736V21.3921H126.472V29.9361Z" fill="white" />
            <path d="M79.2725 74.8641C74.3445 74.8641 69.8645 73.7441 65.8325 71.5041C61.8645 69.2641 58.7285 66.0961 56.4245 62.0001C54.1845 57.8401 53.0645 53.0401 53.0645 47.6001C53.0645 42.2241 54.2165 37.4881 56.5205 33.3921C58.8885 29.2321 62.0885 26.0641 66.1205 23.8881C70.1525 21.6481 74.6645 20.5281 79.6565 20.5281C84.6485 20.5281 89.1605 21.6481 93.1925 23.8881C97.2245 26.0641 100.392 29.2001 102.696 33.2961C105.064 37.3921 106.248 42.1601 106.248 47.6001C106.248 53.0401 105.032 57.8401 102.6 62.0001C100.232 66.0961 97.0005 69.2641 92.9045 71.5041C88.8085 73.7441 84.2645 74.8641 79.2725 74.8641ZM79.2725 67.1841C82.4085 67.1841 85.3525 66.4481 88.1045 64.9761C90.8565 63.5041 93.0645 61.2961 94.7285 58.3521C96.4565 55.4081 97.3205 51.8241 97.3205 47.6001C97.3205 43.3761 96.4885 39.7921 94.8245 36.8481C93.1605 33.9041 90.9845 31.7281 88.2965 30.3201C85.6085 28.8481 82.6965 28.1121 79.5605 28.1121C76.3605 28.1121 73.4165 28.8481 70.7285 30.3201C68.1045 31.7281 65.9925 33.9041 64.3925 36.8481C62.7925 39.7921 61.9925 43.3761 61.9925 47.6001C61.9925 51.8881 62.7605 55.5041 64.2965 58.4481C65.8965 61.3921 68.0085 63.6001 70.6325 65.0721C73.2565 66.4801 76.1365 67.1841 79.2725 67.1841Z" fill="white" />
            <path d="M45.5757 7.08813V14.1921H27.3357V74.0001H18.5997V14.1921H0.263672V7.08813H45.5757Z" fill="white" />
        </svg>
    );
}
export default Name_svg

